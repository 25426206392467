<template>
  <v-main class="pt-4 pb-10 mb-5" >
    <section id="slide" class="px-3">
      <section-header
        header="Casos de Éxito"
        sub-header="NUESTROS CLIENTES"
      />
    </section>

    <VueSlickCarousel v-bind="slickOptions">
      <div v-for="(item,key) in items" :key="key">
        <v-card
          class="ma-4"
          height="160"
          width="210"
        >
          <v-row
            class="fill-height"
            align="center"
            justify="center"
          >
                <v-img
                  :src="item.src"
                  max-width="150"
                  max-height="200"
                  class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
          </v-row>
        </v-card>
      </div>
    </VueSlickCarousel>

  </v-main>



</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  export default {
    components:{ VueSlickCarousel },

    data () {
      return {
        slickOptions: {
          "dots": false,
          "infinite": true,
          "speed": 500,
          "slidesToShow": 4,
          "slidesToScroll": 1,
          "initialSlide": 0,
          "autoplay": true,
          "arrows": false,
          "responsive": [
            {
              "breakpoint": 1024,
              "settings": {
                "autplay": true,
                "slidesToShow": 4,
                "slidesToScroll": 1,
                "infinite": true,
                "dots": false,
                "centerMode": true,
              }
            },
            {
              "breakpoint": 600,
              "settings": {
                "slidesToShow": 2,
                "slidesToScroll": 2,
                "initialSlide": 2,
                "centerMode": true,
              }
            },
            {
              "breakpoint": 480,
              "settings": {
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "centerMode": true,
              }
            }
          ]
        },
        model: null,
        items: [
          {
            src: '/static/clients/aotrauma.jpg',
          },
          {
            src: '/static/clients/aveso.jpg',
          },
          {
            src: '/static/clients/bariatrica.jpg',
          },
          {
            src: '/static/clients/centro-medico-caracas.jpg',
          },
          {
            src: '/static/clients/flamecipp.jpg',
          },
          {
            src: '/static/clients/svem.jpg',
          },
          {
            src: '/static/clients/svg.jpg',
          },
          {
            src: '/static/clients/svo.jpg',
          },
        ],
      }
    },
  }
</script>

<style scoped>

</style>

